import CryptoJS from 'crypto-js';

export const hasClass = (el, cls) => {
  return [...el.classList].includes(cls); //cls without dot
};

export const formatFullDate = (str, item, every) => {
  for (let i = 0; i < str.length; i++) {
    if (!(i % (every + 1))) {
      str = str.substring(0, i) + item + str.substring(i);
    }
  }

  return str.substring(1);
};

export const generateRandomString = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
};

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
};

// Regular expression pattern to match a URL
export const urlPattern =
  /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;

export const formatSortCode = (sortCode) => {
  // const sortCodeStr = String(sortCode);

  // Use regular expressions to split into groups of 2 digits
  const formattedSortCode = sortCode.replace(
    /(\d{2})(\d{2})(\d{2})/,
    '$1-$2-$3'
  );

  return formattedSortCode;
};

export const capitalizeString = (string: string | string[]) => {
  if (string) {
    return Array.isArray(string)
      ? string.map((str) => str[0].toUpperCase() + str.substring(1))
      : string[0].toUpperCase() + string.substring(1);
  }
};

export const formatCardNumber = (cardNumber) => {
  // Remove any non-digit characters
  const digitsOnly = cardNumber.replace(/\D/g, '');

  // Format the card number as XXXX-XXXX-XXXX-XXXX
  const formattedCardNumber = digitsOnly.replace(
    /(\d{4})(\d{4})(\d{4})(\d{4})/,
    '$1-$2-$3-$4'
  );

  return formattedCardNumber;
};

export const validateCardDate = (input) => {
  if (
    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      input
    )
  ) {
    return true;
  }

  return false;
};

export const validateEmail = (value) => {
  const emailValidation =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const emailTest = emailValidation.test(String(value).toLowerCase());

  return emailTest;
};

export const validatePassword = (password) => {
  // Define the rules for password validation
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  // Initialize an empty error message
  let errorMessage = '';

  // Check each rule and build the error message
  if (password.length < minLength) {
    errorMessage += 'Password must be at least 8 characters long. ';
  }
  if (!hasUppercase) {
    errorMessage += 'Password must contain at least one uppercase letter. ';
  }
  if (!hasLowercase) {
    errorMessage += 'Password must contain at least one lowercase letter. ';
  }
  if (!hasSpecialChar) {
    errorMessage += 'Password must contain at least one special character. ';
  }

  // Check if the password meets all the rules
  const isValid = errorMessage === '';

  return { isValid, errorMessage };
};

export const removeLeadingZero = (mobileNo) => {
  // Check if the string starts with '0'
  if (mobileNo.startsWith('0')) {
    // If it starts with '0', remove the leading '0'
    return mobileNo.slice(1);
  } else {
    // If it doesn't start with '0', keep the same value
    return mobileNo;
  }
};

export const hasNonNullProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null) {
      return true; // Found a non-null property
    }
  }
  return false; // All properties are null
};

export const pad = (str, max) => {
  return str.length < max ? pad('0' + str, max) : str;
};

export const limitter = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + '...';
  } else {
    return str;
  }
};

export const getFirstTwoSentences = (text) => {
  // Split the text by sentence-ending punctuation (., !, ?)
  const sentences = text.match(/[^.!?]*[.!?]/g);

  // Return the first two sentences if available
  if (sentences && sentences.length > 1) {
    return sentences[0] + ' ' + sentences[1];
  } else if (sentences && sentences.length === 1) {
    return sentences[0]; // If there's only one sentence, return it
  } else {
    return ''; // If no sentences are found, return an empty string
  }
};

// Example set of airport-related keywords and IATA codes (this list can be extended)
const airportKeywords = [
  'airport',
  'terminal',
  'airfield',
  'international',
  'domestic',
  'aviation',
];

// Sample IATA codes (extend with actual IATA codes if necessary)
const airportIATACodes = [
  'LHR',
  'LTN',
  'LGW',
  'STN',
  'XET',
  'XIT',
  'ZDU',
  'LCY',
  'SEN',
  'NHT',
  'BQH',
  'FAB',
  'OXF',
];

export const isAirportAddress = (address: string): boolean => {
  // Normalize the address by converting to lowercase for keyword matching
  const normalizedAddress = address.toLowerCase();

  // Check if any of the common airport-related keywords exist in the address
  const hasAirportKeyword = airportKeywords.some((keyword) =>
    normalizedAddress.includes(keyword)
  );

  const addressWords = address.toUpperCase().split(/[\s,]+/); // Split by spaces or commas

  // Check if any known IATA codes are present in the address as whole words
  const hasIATACode = airportIATACodes.some((code) =>
    addressWords.includes(code)
  );

  // Return true if the address contains any airport keywords or IATA codes
  return hasAirportKeyword || hasIATACode;
};

// Function to copy text to clipboard
export const copyToClipboard = (text) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard.writeText(text);
  } else {
    console.error('Clipboard API not supported');
    return Promise.reject('Clipboard API not supported');
  }
};

export const encryptData = (data: string, key: string): string => {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
  const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Concatenate IV and encrypted data with a separator
  return (
    iv.toString(CryptoJS.enc.Hex) +
    ':' +
    encrypted.ciphertext.toString(CryptoJS.enc.Hex)
  );
};

export const decryptData = (encryptedData: string, key: string): string => {
  try {
    const [ivHex, ciphertextHex] = encryptedData.split(':'); // Split IV and ciphertext
    if (!ivHex || !ciphertextHex) {
      throw new Error('Invalid encrypted data format.');
    }

    const iv = CryptoJS.enc.Hex.parse(ivHex); // Parse IV
    const ciphertext = CryptoJS.enc.Hex.parse(ciphertextHex); // Parse ciphertext

    // Construct CipherParams object
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: ciphertext,
    });

    const bytes = CryptoJS.AES.decrypt(
      cipherParams,
      CryptoJS.enc.Utf8.parse(key),
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) {
      console.log('Decryption failed or resulted in empty data.');
    }

    return decrypted;
  } catch (error) {
    console.error('Error decrypting data:', error.message);
    throw error;
  }
};

/**
 * Extracts parameters from a query string.
 *
 * @param {string} queryString - The query string containing parameters.
 * @returns {Object} - A key-value pair object of the parameters.
 */
export const extractParams = (queryString) => {
  return queryString
    .split('&') // Split by `&` to separate each key-value pair
    .reduce((acc, param) => {
      const [key, value] = param.split('='); // Split by `=` to separate key and value
      acc[key] = decodeURIComponent(value); // Decode and add to the result object
      return acc;
    }, {});
};
